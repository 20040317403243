import { AnalyticsStore } from '@cointracker/analytics';
import { useEffect, useState } from 'react';
import { useCelloAttribution } from './loadCelloAttributionLibrary';

export function CelloCookieConsent() {
  const [isEnabled, setIsEnabled] = useState(false);
  const isProductionEnv = import.meta.env.MODE === 'production';

  useEffect(() => {
    const unsubscribe = AnalyticsStore.subscribe((state) => {
      if (state.hasUserGivenConsent && state.areOptionalAnalyticsAllowed) {
        setIsEnabled(true);
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  useCelloAttribution(isEnabled, isProductionEnv);

  return null; // This component doesn't render anything
}
