import { useEffect } from 'react';
import useScript from '../../hooks/useScript';
import { storeUccInCookiesUsingCelloLib } from './storeCelloReferrer';

export function useCelloAttribution(
  enabled: boolean,
  isProductionEnv: boolean,
) {
  // We use the production URL by default, but switch to the sandbox URL in non-production environments
  let script_url =
    'https://assets.cello.so/attribution/latest/cello-attribution.js';
  if (!isProductionEnv) {
    script_url =
      'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js';
  }
  // Load script only when consent is given
  const status = useScript(enabled ? script_url : null, {
    type: 'module',
  });

  useEffect(() => {
    if (status === 'ready') {
      storeUccInCookiesUsingCelloLib();
    } else if (status === 'error') {
      console.error('❌ Failed to load Cello Attribution script');
    }
  }, [status]);
}
